.shareLinkButton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  15% {
    opacity: 1;
    transform: translateY(0);
  }
  85% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.container {
  position: relative;
}

.copyConfirmation {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 8px;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  color: white;
  animation: fadeInOut 2s ease-in-out forwards;
  white-space: nowrap;
  z-index: 1000;
  pointer-events: none;
}
