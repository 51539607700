@value app: '../../pages/app.css';
@value mobile-breakpoint from app;

.bottomBar {
  width: 100%;
  background-color: black;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;
}

.headerFooter {
  padding: 22px 32px;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
  color: white;
}

.jascroLinkContainer {
  padding: 22px 32px;
}
