.container {
  box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  transition: box-shadow 200ms, transform 200ms;
  cursor: pointer;
  display: flex;
  position: relative;
}

.container:active {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
  transform: translateY(2px);
}
