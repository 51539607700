.link {
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
  color: white;
  opacity: 0.7;
  font-size: 14px;
  transition: opacity 0.2s ease;
}

.dark {
  color: black;
}

.light {
  color: white;
}

.link:hover {
  opacity: 1;
  text-decoration: underline;
}

@media (max-width: mobile-breakpoint) {
  .link {
    text-align: end;
  }
}
