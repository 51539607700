@value app: '../../pages/app.css';
@value mobile-breakpoint from app;

.page {
  display: flex;
  flex-direction: row;
  margin-top: 58px;
  background-color: #f9fafd;
  overflow-y: hidden;
  height: calc(100vh - 58px);
}

.page::-webkit-scrollbar {
  width: 10px;
}

/* Handle */
.page::-webkit-scrollbar-thumb {
  background: #d4d3d3;
  border-radius: 10px;
}
/* Handle on hover */
.page::-webkit-scrollbar-thumb:hover {
  background: #d6d1d1;
}

.scrollableContainer {
  overflow-y: scroll;
  display: flex;
  flex: 1;
}

@media (max-width: mobile-breakpoint) {
  .page {
    height: 100%;
    min-height: calc(100vh - 58px);
  }
}
